import React from "react"
import Layout from "../components/layout"
import Headline from "../components/headline/headline.component"
import ProjectPreview from "../components/project-preview/project-preview.component"
import SEO from "../components/seo"
const ProjectsPage = () => {
  return (
    <Layout>
      <SEO
        title="My Projects"
        description="These are some applications that I have built"
      />
      <Headline
        mainText="My Projects"
        subText="Some software applications that I have built"
      />
      <div className="container">
        <ProjectPreview />
      </div>
    </Layout>
  )
}

export default ProjectsPage
